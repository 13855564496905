import React from "react"
import "../components/login.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"

const SocialMediaLogin = ({ type, googleLogin }) => {
  return (
    <>
      <div className="signup-or-separator">
        <h1>
          <span className="signup-or-separator--text h6">1-Click Login With...</span>
        </h1>
        <hr></hr>
      </div>
      <div className="social">
        <a
          href="#"
          className="btn btn-social btn-facebook btnComingSoon"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Coming soon"
        >
          <i className="bi bi-facebook" />
        </a>{" "}
        <a
          href="#"
          className="btn btn-social btn-google btnComingSoon"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Coming soon"
          // onClick={() => googleLogin()}
        >
          <i className="bi bi-google" />
        </a>{" "}
        {type !== "signup" && (
          <>
            <a
              className="btn btn-social btn-windows btnComingSoon"
              href="#"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Coming soon"
            >
              <i className="bi bi-windows" />
            </a>{" "}
            <a
              className="btn btn-social btn-apple btnComingSoon"
              href="#"
              data-bs-toggle="tooltip"
              data-placement="bottom"
              title="Coming soon"
            >
              <i className="bi bi-apple" />
            </a>
          </>
        )}
      </div>
    </>
  )
}

export default SocialMediaLogin
