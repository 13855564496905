import React, { useState } from "react"
import { useGoogleLogin } from "@react-oauth/google"
import { Link, navigate } from "gatsby"
import Axios from "axios"
import LoginFooter from "./loginFooter"
import TermsAndConditions from "./termsAndConditions"
import SocialMediaLogin from "./SocialMediaLogin"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "../components/login.css"
import "@fontsource/noto-sans"
import AxiosInstance from "../services/axiosInstance"
import axios from "axios"

const Login = ({ switchUserEmail }) => {
  const [username, setUsername] = useState(""),
    [password, setPassword] = useState(""),
    [errorMessage, setErrorMessage] = useState(""),
    [values, setValues] = useState({
      loading: false,
    }),
    [securityMsg, setSecurityMsg] = useState(
      typeof localStorage !== "undefined" ? localStorage.getItem("securityMsg") : ""
    )
  const { loading } = values

  if (typeof localStorage !== "undefined") {
    localStorage.removeItem("securityMsg")
  }

  const signin = async user => {
    let formData = new FormData()
    formData.append("username", String(user.email))
    formData.append("password", user.password)
    formData.append("grant_type", process.env.GATSBY_USER_GRANT_TYPE_LOGIN)
    formData.append("scope", process.env.GATSBY_USER_SCOPE)
    formData.append("client_id", process.env.GATSBY_USER_CLIENT_ID)
    formData.append("client_secret", process.env.GATSBY_USER_CLIENT_SECRET)

    let responseData = Axios.post(process.env.GATSBY_API_URL + "/token", formData, {
      headers: {
        Accept: "*/*",
      },
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        setErrorMessage(error.response.data.message)
        setValues({
          ...values,
          loading: false,
        })
      })

    return responseData
  }

  const handleLogin = (event, email, password) => {
    event.preventDefault()
    setValues({ ...values, loading: true })
    signin({ email, password })
      .then(data => {
        if (data?.access_token) {
          authenticate(data, () => {
            setValues({
              ...values,
              loading: false,
            })
          })
        }
      })
      .catch(() => {
        console.log("something  went wrong ")
      })
  }

  const setUserDetails = async token => {
    AxiosInstance.get("/settings/general-setting/all-data").then(response => {
      setUserData(response.data, () => {
        setValues({
          ...values,
          loading: false,
        })
      })
    })
  }

  const getLanguageData = () => {
    AxiosInstance.get(`/settings/general-setting/language-data`)
      .then(response => {
        if (response.data.data.length > 0) {
          response.data.data.map(data => {
            if (data.type === "Currency") {
              getCurrency(data)
            }
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getCurrency = data => {
    const uninterceptedAxiosInstance = axios.create()
    uninterceptedAxiosInstance
      .get(`${process.env.GATSBY_API_URL}/currency/list`)
      .then(response => {
        let selectedCurrency = response.data.data.filter(currency => {
          return currency.code === data.value
        })
        localStorage.setItem("Currency", JSON.stringify(selectedCurrency[0]))
      })
      .catch(error => {
        console.log(error)
      })
  }

  const setUserData = userData => {
    userData.data.map((data, index) => {
      localStorage.setItem(data.type, data.value)
    })
    if (localStorage.getItem("lastpath") && localStorage.getItem("lastpath") !== "/login/") {
      navigate(localStorage.getItem("lastpath"))
      window.localStorage.removeItem("lastpath")
    } else {
      navigate("/home")
    }
  }
  const authenticate = data => {
    if (data.access_token) {
      localStorage.setItem("jwt", data.access_token)
      localStorage.setItem("refreshJwt", data.refresh_token)
      if (switchUserEmail) {
        localStorage.setItem("x-switch-user", switchUserEmail)
      }
      setUserDetails(data.access_token)
      getLanguageData()
      getCurrency()
    }
  }

  /*const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      Axios.post(`${process.env.GATSBY_API_URL}/login/google`, {
        code: codeResponse.code
      }).then(response => {
        console.log(response)
        authenticate(response.data, () => {
          setValues({
            ...values,
            loading: false,
          })
        })
      }).catch((error) => console.log(error))
    },
    onError: (error) => console.log('Login Failed:', error),
    flow: "auth-code"
  });*/

  return (
    <React.Fragment>
      <section className="loginSection">
        <div className="loginContainer">
          {securityMsg && (
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="alert alert-danger" role="alert">
                  {securityMsg}
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12 col-xs-12 text-center">
              <SocialMediaLogin />
              <div className="signup-or-separator">
                <span className="signup-or-separator--text h6">or</span>
                <hr />
              </div>
              <form
                onSubmit={event => {
                  handleLogin(event, username, password)
                }}
                id="loginForm"
              >
                <div className="input-group mb-3">
                  <span
                    className="input-group-text"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Email"
                  >
                    @
                  </span>
                  <input
                    id="username"
                    className="form-control formControlInput"
                    type="email"
                    tabIndex="1"
                    value={username}
                    placeholder="Email"
                    required
                    onChange={event => {
                      setUsername(event.target.value)
                    }}
                  />
                  <label id="username-error" className="error d-none" htmlFor="username"></label>
                </div>
                <div className="input-group mb-3">
                  <span
                    className="input-group-text"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Password"
                  >
                    <i className="bi bi-lock-fill" />
                  </span>
                  <input
                    id="password"
                    className="form-control formControlInput"
                    type="password"
                    tabIndex="2"
                    placeholder="Password"
                    required
                    onChange={event => {
                      setPassword(event.target.value)
                    }}
                  />
                </div>
                {errorMessage && <div className="error">{errorMessage}</div>}
                <div className="mb-3 text-start remember-me">
                  <label id="i">
                    {" "}
                    <input type="checkbox" defaultChecked={true} value="on" />{" "}
                    <span className="lbl"> Remember me</span>{" "}
                  </label>
                  <Link className="forgotLink float-end" to="/reset-password/request" data-modal="forgotPage">
                    Login Troubles?
                  </Link>
                </div>
                <div className="mb-3">
                  <button
                    type="submit"
                    disabled={loading}
                    id="loginModalButton"
                    className="btn btn-primary w-100"
                  >
                    <i className="bi bi-arrow-right-circle" /> Login{" "}
                    {loading && <i className="spinner-border spinner-border-sm" />}
                  </button>
                </div>
              </form>
              <hr></hr>
              <div className="text-center">
                <p>
                  Don't have an account?{" "}
                  <Link to="/signup" className="btn btn-secondary">
                    <i className="bi bi-check2" /> SIGNUP
                  </Link>
                </p>
              </div>
              <TermsAndConditions />
            </div>
          </div>
        </div>
      </section>
      <LoginFooter />
    </React.Fragment>
  )
}

export default Login
